









































































































































































.notifications .alert {
  position: fixed;
  z-index: 10000;

  &[data-notify='container'] {
    width: 400px;
  }

  &.center {
    margin: 0 auto;
  }
  &.left {
    left: 20px;
  }
  &.right {
    right: 20px;
  }
}
